/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SortColumn {
    NAME = 'name',
    TYPE = 'type',
    STATUS = 'status',
    LESSEE = 'lessee',
    LESSOR = 'lessor',
    SECTOR = 'sector',
    COMMENCEMENT_DATE = 'commencementDate',
    EXPIRY_DATE = 'expiryDate',
    SIZE = 'size',
    COUNTRY = 'country',
    STATE = 'state',
    TRADING_NAME = 'tradingName',
    COMPLETENESS = 'completeness',
    PROPERTY_NAME = 'propertyName',
    PROPERTY_UNIT = 'propertyUnit',
    CITY = 'city',
    ADDRESS = 'address',
    POSTCODE = 'postcode',
    DOCUMENT_COUNT = 'documentCount',
    BASE_RENT = 'baseRent',
    BASE_RENT_FREQUENCY = 'baseRentFrequency',
    BASE_RENT_PER_SQM = 'baseRentPerSqm',
    BASE_RENT_PER_SIZE_UNIT = 'baseRentPerSizeUnit',
    PUBLISHED_AT = 'publishedAt',
    UPDATED_AT = 'updatedAt',
    APPROVED_VARIATION_COUNT = 'approvedVariationCount',
    VARIATION_COUNT = 'variationCount',
}
